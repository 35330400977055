import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import LoadSpinner from '../components/loadSpinner';
import ServiceModal from '../components/serviceModal';

import { rootPath } from "../utils";
import { format, subDays } from 'date-fns';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

/* Images */
import logo_c from '../../assets/imgs/logo_b1.png';

const initialServiceInfo = { name:"", date:'', viewing:'', location:"", address:"", _id:null, image:"" };

class ScheduleTool extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, showModal: false,
            locationList:[
                {label: "Chapel A", value:"Chapel A"}, {label: "Chapel B", value:"Chapel B"},
                {label: "Chapel C & D", value:"Chapel C & D"}, {label: "Chapel E", value:"Chapel E"}],
            search:"", start_dt: subDays(new Date(),1), pageCount:1, currentPg:1,
            serviceList:[], loginName: "", serviceInfo: {...initialServiceInfo}
        }  
        
        this.setModal = this.setModal.bind(this);
        this.getLoginInfo = this.getLoginInfo.bind(this);
        this.signOut = this.signOut.bind(this);
        
        this.setLocation = this.setLocation.bind(this);    
        this.handleSpecialChange = this.handleSpecialChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);

        this.pageChange = this.pageChange.bind(this);
        this.getServiceList = this.getServiceList.bind(this);
        this.selectService = this.selectService.bind(this);
        this.addUpdateService = this.addUpdateService.bind(this);
        this.removeService = this.removeService.bind(this);
        this.parseDate = this.parseDate.bind(this);
    }  

    setModal(status){
        let tmpServiceInfo = ((!status) ? { ...initialServiceInfo } : {...this.state.serviceInfo});

        this.setState({ showModal: status, serviceInfo: tmpServiceInfo });
    }

    getLoginInfo(){
        try {
            var sessionInfo = localStorage.getItem(this.props.mySessKey);
            if(sessionInfo){
                var { localUser, isExpired } = this.props.parseToken(sessionInfo); 
                
                if(localUser && !isExpired) {
                    this.setState({ loginName: localUser.name });
                }                
            }
        }
        catch(ex){
            console.log("Error Check Active User: ",ex);
        }
    }

    signOut(){
        try {
            this.props.userHandler(null,function(){
                window.location.reload(false);
            });            
        }
        catch(ex){
            console.log("Error with sign out: ",ex);
        }
    }

    setLocation(val){
        try {
            let tmpService = { ...this.state.serviceInfo, location: val};
            this.setState({ "serviceInfo": tmpService });
        }
        catch(ex){
            console.log(" [Error] Setting Location Text: ",ex);
        }
    }

    handleSpecialChange(e){
        try {
            let tmpService = { ...this.state.serviceInfo, location: {label: e, value: e}};
            this.setState({ "serviceInfo": tmpService });
        }
        catch(ex){
            console.log(" [Error] Handling Special Change: ",ex);
        }
    }

    handleSearchChange(e){
        var self = this;
        try {
            var name = e.target.name;
            var value = e.target.value;
            
            this.setState({ [name]: value },() => {
                if(name === "search") {
                    self.getServiceList(self.state.search, self.state.currentPg);
                }
            });
        }
        catch(ex){
            console.log(" [Error] Handling Change: ",ex);
        }
    }

    handleChange(e){
        try {
            var name = e.target.name;
            var value = e.target.value;
            let tmpService = { ...this.state.serviceInfo, [name]: value};
            this.setState({ "serviceInfo": tmpService });
        }
        catch(ex){
            console.log(" [Error] Handling Change: ",ex);
        }
    }

    handleDateChange(e, name){
        try {
            var date = e._d;
            let tmpService = { ...this.state.serviceInfo, [name]: date};
            this.setState({ "serviceInfo": tmpService });
        }
        catch(ex){
            console.log(" [Error] Handling Change: ",ex);
        }
    }

    handleDateRangeChange(e){
        let self = this;
        try {
            var date = e._d;
            this.setState({ "start_dt": date }, ()=> {
                self.getServiceList(self.state.search, 1); 
            });
        }
        catch(ex){
            console.log(" [Error] Handling Date Range Change: ",ex);
        }
    }

    pageChange(type){
        var self = this;
        try {
            if(type === "prev"){
                if(this.state.currentPg > 1){
                    this.setState({ currentPg: this.state.currentPg - 1}, () =>{
                        self.getServiceList(self.state.search, self.state.currentPg);
                    })
                }
            }
            else {
                if(this.state.currentPg < this.state.pageCount){
                    this.setState({ currentPg: this.state.currentPg + 1}, () =>{
                        self.getServiceList(self.state.search, self.state.currentPg);
                    });
                }
            }
        }
        catch(ex){
            console.log("[ERROR] page change: ",ex);
        }
    }

    getServiceList(search, page){
        var self = this;
        try {
            this.setState({ loading: true }, ()=>{
                let tmp_start_dt = format(self.state.start_dt,'MM-dd-yyyy');
                axios.get(`${rootPath}/api/getServices?search_query=${search}&size=${8}&page=${page}&start_dt=${tmp_start_dt}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Service List(1): ", response.data.error);
                            self.setState({loading:false});
                        }
                        else if(response.data.results.list && response.data.results.list.length >= 0){
                            self.setState({loading:false, serviceList: response.data.results.list, pageCount: response.data.results.pageCount });
                        }
                    }); 
            });
        }
        catch(ex){
            console.log(" [Error] Getting Service List: ",ex);
        }
    }

    selectService(service){
        try {
            let tmpService = {
                name:service?.name ?? '',  date:service?.date ?? '', 
                viewing:service?.viewing ?? '', address: service?.address ?? '',
                location:{ label: service?.location, value: service?.location }, 
                _id:service?._id ?? null, image:service?.image ?? ''
            }

            this.setState({  serviceInfo: tmpService , showModal: true });
        }
        catch(ex){
            console.log("[Error] Selecting Service");
        }
    }

    addUpdateService(){
        var self = this;
        try {
            let serviceInfo = this.state.serviceInfo;

            if(!(serviceInfo.name?.length > 0 && serviceInfo.location?.value?.length > 0)) {
                alert(`Please Add Name & Location for service prior to ${(serviceInfo._id) ? 'updaing' : 'adding'}!`);
            }
            else if((serviceInfo.date && !isNaN(serviceInfo.date)) || confirm("Service Date is N/A, therefor it will display as TBD, Is this ok?")){
                this.setState({ loading: true }, ()=> {
                    var sessionInfo = localStorage.getItem(self.props.mySessKey);
                    var getHeaders = { 'Content-Type': 'application/json', 'Authorization': sessionInfo };
                    var postData = { ...serviceInfo, location: serviceInfo.location?.value };
                    
                    axios.post(self.props.rootPath + "/api/auth/updateService", postData, { headers: getHeaders })
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(" [Error] Updating Service: ", response.data.error);
                                alert(`Sorry Unable to Add/Update Service: ${response.data.error}`);
                                self.setState({loading:false});
                            }
                            else {
                                self.setState({showModal: false, serviceInfo: {...initialServiceInfo} }, ()=>{
                                    self.getServiceList(self.state.search, 1);
                                });
                            }
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] Clear Selected")
        }
    }

    removeService(id){
        var self = this;
        try {
            var firm = window.confirm("Do you want to remove this service?");

            if(firm === true){ 
                this.setState({ loading: true }, ()=> {
                    var sessionInfo = localStorage.getItem(self.props.mySessKey);
                    var getHeaders = { 'Content-Type': 'application/json', 'Authorization': sessionInfo };
                    var postData = { id: id };
                    
                    axios.post(self.props.rootPath + "/api/auth/removeService", postData, { headers: getHeaders })
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(" [Error] Updating Service: ", response.data.error);
                                self.setState({loading:false});
                            }
                            else {
                                self.getServiceList(self.state.search, 1);                            
                            }
                        }); 
                });
            }
        }
        catch(ex){
            console.log("[Error] Clear Selected")
        }
    }

    componentDidMount(){
        this.getLoginInfo();
        this.getServiceList("", 1);
    }

    parseDate(stdate, type){
        var ret = null;
        var Month = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];

        try {
            if(!stdate){ return null; }

            var date = new Date(stdate);
            switch(type){
                case "time":
                    ret = ((date.getHours() > 12) ? (date.getHours() - 12) : date.getHours()) +":"
                    + ((date.getMinutes() < 10) ? "0"+ date.getMinutes() : date.getMinutes())
                    + ((date.getHours() >= 12) ? " PM" : " AM");
                    break;
                case "date":
                    ret = Month[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
                    break;
                default:
                    ret = null;
                    break;
            }
        }
        catch(ex){
            console.log("Error parsing date: ", ex);
        }
        return ret;
    }

    render(){  
        return(
            <div className="page-body admin scheduleTool">
                {this.state.loading && <LoadSpinner /> }
                <Link className="back-btn" to="/admin">
                    <i className="fas fa-chevron-left" />
                    <span>Back To Admin Portal</span>
                </Link>
                <div className="login-info">
                    <span>Welcome, {this.state.loginName}</span>
                    <i className="far fa-times-circle" onClick={this.signOut}/>
                </div>
                <div className="title">
                    <img src={logo_c} alt="Gilmore Funeral Home Crest"/>
                    <h1>Service Updater Tool</h1>
                </div>

                <div className="add-btn-container">
                    <div className="input-field btn-container">
                        <div className="lBtn" onClick={() => this.selectService({})}>
                            <span>Add New Service</span>
                            <i className="btn-icon fas fa-plus" />
                        </div>
                    </div>
                </div>

                <div className="service-list admin-list">
                    <div className="list-header">
                        <div className="search-container">
                            <div className="search-field c-blk">
                                <i className="fas fa-search" />
                                <input type="text" name="search" placeholder="Search Name" onChange={this.handleSearchChange}/>
                            </div>
                        </div>

                        <div className="start-date-field-container">
                            <div className="input-title">Start Date</div>
                            <div className="input-container open">
                                <i className="fas fa-calendar-alt"></i>
                                <Datetime value={this.state.start_dt} onChange={this.handleDateRangeChange} displayTimeZone={null} timeFormat={false} renderInput={(props) => {
                                    return <input {...props} value={(this.state.start_dt) ? props.value : ''} />
                                }}/>
                            </div>
                        </div>
                    </div>
                    <table className="service-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Viewing Date & Time</th>
                                <th>Service Date & Time</th>
                                <th>Service Location</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.serviceList.map((service, i) => (
                                <tr key={i} className={(service._id === this.state._id ? "sel":"")}>
                                    <td>
                                        <div className='list-img-container'>
                                            {service?.image ? <img src={service.image} alt={`${service.name} Image`} /> :
                                                <div className='empty-img' />
                                            }
                                        </div>
                                    </td>
                                    <td>{service.name}</td>
                                    <td>{this.parseDate(service.viewing,"date")} {this.parseDate(service.viewing,"time")}</td>
                                    {!service?.date ?
                                        <td><span className="special-note">Service TBD</span></td> :
                                        <td>{this.parseDate(service.date,"date")} {this.parseDate(service.date,"time")}</td> 
                                    }
                                    <td className='location'>
                                        <span>{service.location}</span>
                                        <span className='address'>{service.address}</span>
                                    </td>
                                    <td>
                                        <div className="mini-btn-container">
                                            <div className="view-btn edit" onClick={() => this.selectService(service)}><i className="fas fa-edit"/></div>
                                            <div className="view-btn remove" onClick={()=> this.removeService(service._id)}><i className="fas fa-trash-alt" /></div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            {this.state.serviceList.length === 0 &&
                                <tr className="empty-table"><td colSpan={5}>No Services Found</td></tr>
                            }
                        </tbody>
                    </table>

                    {this.state.serviceList.length > 0 &&
                        <div className="table-control">
                            <div className="scroll-ctrl">
                                <div className={"ctrl-item left" + (this.state.currentPg === 1 ? " disabled":"")} onClick={() => this.pageChange("prev")}><i className="fas fa-chevron-left"/></div>
                                <div className={"ctrl-item right" + (this.state.currentPg === this.state.pageCount ? " disabled":"")} onClick={() => this.pageChange("next")}><i className="fas fa-chevron-right"/></div>
                            </div>

                            <div className="page-info">
                                <div className="pg-info">Page {this.state.currentPg} of {this.state.pageCount}</div>                            
                            </div>
                        </div>
                    }
                </div>

                <ServiceModal show={this.state.showModal} setShow={this.setModal} serviceInfo={this.state.serviceInfo} 
                    setLocation={this.setLocation} handleSpecialChange={this.handleSpecialChange} 
                    handleChange={this.handleChange} handleDateChange={this.handleDateChange }
                    addUpdateService={this.addUpdateService}
                />
            </div>
        );
    }
}
export default ScheduleTool;