import React, { Component } from 'react';
import { Link } from "react-router-dom";
/* Images */
import logo_c from '../../assets/imgs/logo_b1.png';

class Admin extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false, loginName: ""
        }  
        
        this.getLoginInfo = this.getLoginInfo.bind(this);
        this.signOut = this.signOut.bind(this);
    } 

    getLoginInfo(){
        try {
            var sessionInfo = localStorage.getItem(this.props.mySessKey);
            if(sessionInfo){
                var { localUser, isExpired } = this.props.parseToken(sessionInfo); 
                
                if(localUser && !isExpired) {
                    this.setState({ loginName: localUser.name });
                }                
            }
        }
        catch(ex){
            console.log("Error Check Active User: ",ex);
        }
    }

    signOut(){
        try {
            this.props.userHandler(null,function(){
                window.location.reload(false);
            });            
        }
        catch(ex){
            console.log("Error with sign out: ",ex);
        }
    }

    componentDidMount(){ this.getLoginInfo(); }

    render(){  
        return(
            <div className="page-body admin">
                <div className="login-info">
                    <span>Welcome, {this.state.loginName}</span>
                    <i className="far fa-times-circle" onClick={this.signOut}/>
                </div>
                <div className="title">
                    <img src={logo_c} alt="Gilmore Funeral Home Crest"/>
                    <h1>Staff Admin Portal</h1>
                </div>

                <div className="tool-section">
                    <Link className='tool-item lifted' to="/serviceTool">
                        <div className='title'>
                            <h1>Service Schedule Tool</h1>
                            <p>Tool to manage and display the base information and location of upcoming services on the public facing website.</p>
                        </div>

                        <i className="far fa-calendar-alt" />
                    </Link>

                    <Link className='tool-item lifted' to="/userManagement">
                        <div className='title'>
                            <h1>User Management Tool</h1>
                            <p>This tool is to help manage site admins that have access to website tools.</p>
                        </div>

                        <i className="fas fa-users" />
                    </Link>
                </div>
            </div>
        );
    }
}
export default Admin;