import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import Resizer from "react-image-file-resizer";

import 'rodal/lib/rodal.css';
import Rodal from 'rodal';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const modalWidth = window.outerWidth ? (window.outerWidth >= 770 ? (window.outerWidth * .5) : (window.outerWidth * .9)) : 500, 
        modalHeight = (window.outerWidth && (window.outerWidth < 770) ? (window.outerHeight * .7) : 500);

class ServiceModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            locationList:[
                {label: "Chapel A", value:"Chapel A"}, {label: "Chapel B", value:"Chapel B"},
                {label: "Chapel C & D", value:"Chapel C & D"}, {label: "Chapel E", value:"Chapel E"}]
        }

        this.photoRef = React.createRef();
        
        this.specialLocation = this.specialLocation.bind(this);      
        this.parseDate = this.parseDate.bind(this);
        this.setImageFile = this.setImageFile.bind(this);
    }


    specialLocation(){
        let ret = false;

        try {
            let tmpList = this.state.locationList.map(function(rt) { return rt.label; });
            let tmpLoc = (this.props.serviceInfo?.location?.label ? this.props.serviceInfo?.location?.label : this.props.serviceInfo?.location);
            ret = (this.props.serviceInfo?.location?.label?.length > 0 && tmpList.indexOf(tmpLoc) < 0);
        }
        catch(ex){
            console.log(` [Error] checking special location: ${ex}`);
        }

        return ret;
    }

    parseDate(stdate, type){
        var ret = null;
        var Month = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];

        try {
            if(!stdate){ return null; }

            var date = new Date(stdate);
            switch(type){
                case "time":
                    ret = ((date.getHours() > 12) ? (date.getHours() - 12) : date.getHours()) +":"
                    + ((date.getMinutes() < 10) ? "0"+ date.getMinutes() : date.getMinutes())
                    + ((date.getHours() >= 12) ? " PM" : " AM");
                    break;
                case "date":
                    ret = Month[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
                    break;
                default:
                    ret = null;
                    break;
            }
        }
        catch(ex){
            console.log("Error parsing date: ", ex);
        }
        return ret;
    }

    setImageFile(e){
        let self = this;
        try {
            if (e.target.files[0]) {
                Resizer.imageFileResizer( e.target.files[0],
                    400, 400, "JPEG", 50, 0,
                    (uri) => {
                      self.props.handleChange({ target:{ name: "image", value: uri}});
                    },
                    "base64", 200, 200
                  );
            }
        }
        catch(ex){
            console.log(`Error setting image file: ${ex}`);
        }
    }
    
    render(){ 
        return( 
            <>
                <Rodal visible={this.props.show} onClose={()=> this.props.setShow(false)} animation={"zoom"} width={modalWidth} height={modalHeight}>
                    <div className='service-form-list'>
                        <div className='header-field'>
                            <h1>Add/Update Service</h1>
                            <span>Add or update all service information with new image feature to upload a image.</span>
                        </div>

                        <div className='img-input'>
                            {this.props?.serviceInfo?.image?.length > 0 &&
                                <div className='clear-img-container'>
                                    <div className='clear-img-btn lifted' onClick={() => { this.props.handleChange({ target:{ name: "image", value: ""}})}}>
                                        <i className="fas fa-times" />
                                    </div>
                                </div>
                            }
                            <div className='img-container'>
                                {this.props?.serviceInfo?.image?.length > 0 ? 
                                    <img src={this.props.serviceInfo.image} className='image-display' /> :
                                    <div className='empty-img' />
                                }
                            </div>
                            <div className='img-selector-container'>
                                <div className='img-selector lifted' onClick={()=> this.photoRef.current.click() }>
                                    <span>Choose Image</span>
                                    <i className="btn-icon fas fa-image" />
                                </div>
                                <input className='hiddenInput' type="file" accept="image/*" name="image" ref={this.photoRef} onChange={this.setImageFile} />
                            </div>
                        </div>

                        <div className="input-field sz-4">
                            <div className="input-title">Name</div>
                            <div className="input-container">
                                <i className="fas fa-signature"></i>
                                <input type="text" name="name" value={this.props.serviceInfo?.name} autoComplete="off" onChange={this.props.handleChange}/>
                            </div>
                        </div>

                        <div className="input-field sz-6">
                            <div className="input-title">Location <span>(Type In Location Name Or Select Chapel)</span></div>
                            <div className="input-container over">
                                <i className="fas fa-map-pin"></i>
                                <CreatableSelect className="create-input" isClearable onChange={this.props.setLocation} value={this.props.serviceInfo?.location} 
                                    onCreateOption={this.props.handleSpecialChange} options={this.state.locationList}/>
                            </div>
                        </div>

                        <div className="input-field sz-5">
                            <div className="input-title">Viewing Date & Time</div>
                            <div className="input-container open">
                                <i className="fas fa-calendar-alt"></i>
                                <Datetime value={this.props.serviceInfo?.viewing} onChange={(e) => this.props.handleDateChange(e,'viewing')} displayTimeZone={null} renderInput={(props) => {
                                    return <input {...props} value={(this.props.serviceInfo?.viewing) ? props.value : ''} />
                                }}/>
                            </div>
                        </div>

                        <div className="input-field sz-5">
                            <div className="input-title">Service Date & Time</div>
                            <div className="input-container open">
                                <i className="fas fa-calendar-alt"></i>
                                <Datetime value={this.props.serviceInfo?.date} onChange={(e) => this.props.handleDateChange(e,'date')} displayTimeZone={null} renderInput={(props) => {
                                    return <input {...props} value={(this.props.serviceInfo?.date) ? props.value : ''} />
                                }}/>
                            </div>
                        </div>
                        
                        {this.specialLocation() &&
                            <div className="input-field sz-10">
                                <div className="input-title">Address</div>
                                <div className="input-container">
                                    <i className="fas fa-map-pin" />
                                    <input type="text" name="address" value={this.props.serviceInfo?.address} autoComplete="off" onChange={this.props.handleChange}/>
                                </div>
                            </div>
                        }

                        <div className="add-btn-container">
                            <div className="input-field btn-container">
                                <div className="lBtn" onClick={this.props.addUpdateService}>
                                    <span>Save</span>
                                    <i className="btn-icon fas fa-save" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Rodal>
            </>
        )
    };

}

export default ServiceModal;