import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import LoadSpinner from '../components/loadSpinner';

/* Images */
import logo_c from '../../assets/imgs/logo_b1.png';

var saverInt = null;
const saveStatus = { 
    0: { "title": "up to date", "icon":"fas fa-check"}, 
    1: { "title": "saving", "icon":"fas fa-sync-alt"}, 
    2: { "title": "pending changes", "icon":"fas fa-exclamation-circle"} 
};

let updateDict = {};

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, loginName: "",
            userList:[], saveStatus:0, localId: null
        }  
        
        this.getLoginInfo = this.getLoginInfo.bind(this);
        this.signOut = this.signOut.bind(this);

        this.loadUserList = this.loadUserList.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.toggleUserAdmin = this.toggleUserAdmin.bind(this);
        this.activateSave = this.activateSave.bind(this);
        this.saveUserList = this.saveUserList.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.createUser = this.createUser.bind(this);
    } 

    getLoginInfo(){
        try {
            var sessionInfo = localStorage.getItem(this.props.mySessKey);
            if(sessionInfo){
                var { localUser, isExpired } = this.props.parseToken(sessionInfo); 
                
                if(localUser && !isExpired) {
                    this.setState({ loginName: localUser.name });
                }                
            }
        }
        catch(ex){
            console.log("Error Check Active User: ",ex);
        }
    }

    signOut(){
        try {
            this.props.userHandler(null,function(){
                window.location.reload(false);
            });            
        }
        catch(ex){
            console.log("Error with sign out: ",ex);
        }
    }

    loadUserList(){
        var self = this;
        try {
            var token = localStorage.getItem(this.props.mySessKey);
            var { localUser } = this.props.parseToken(token); 

            if(token){
                this.setState({ loading: true, localId: localUser._id }, ()=> {
                    axios.get(self.props.rootPath + "/api/auth/getAll", {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error || !response.data.results){
                            console.log(" [Error] Loading User List (E1): ", response.data.error);
                        } else{
                            self.setState({ userList: response.data.results });
                        }
                    }).catch(function (error) {
                        console.log("[Error] Loading User List (E2)",error);
                    })
                    .then(function(){
                        self.setState({ loading: false });
                    });  
                });
            }
        }
        catch(ex){
            console.log("[Error] Loading Draft List:",ex);
        }
    }

    onElementChange(e, idx) {
        var self = this;
        try {
            var tmpList = this.state.userList;
            tmpList[idx][e.target.name] = e.target.value;

            this.setState({ formList:tmpList, saveStatus: 2 }, () => { 
                var validUser = validateUser(tmpList[idx]);
                if(validUser) {
                    updateDict[idx] = true;
                }
                else if(idx in updateDict){
                    delete updateDict[idx];
                }
                self.activateSave();
             });
        }
        catch(ex){
            console.log("[Error] on element change: ",ex);
        }
    }

    toggleUserAdmin(idx){
        var self = this;
        try {
            var tmpList = this.state.userList;
            tmpList[idx].admin = !tmpList[idx].admin;

            this.setState({ formList:tmpList, saveStatus: 2 }, () => { 
                var validUser = validateUser(tmpList[idx]);
                if(validUser) {
                    updateDict[idx] = true;
                }
                else if(idx in updateDict){
                    delete updateDict[idx];
                }
                self.activateSave();
            });
        }   
        catch(ex){
            console.log("[Error] toggling admin change: ",ex);
        }
    }

    activateSave(){
        var self = this;
        try {
            if(saverInt != null) {
                clearTimeout(saverInt); saverInt = null;
            }

            if(Object.keys(updateDict).length > 0){
                saverInt = setTimeout(function(){ 
                    self.saveUserList();
                }, 1000);
            }
            else {
                self.setState({ loading: false, saveStatus: 0 });
            }
        }
        catch(ex){
            console.log("[Error] Activiting Save: ",ex);
        }
    }

    saveUserList(){
        var self = this;
        try {
            var saveList = Object.keys(updateDict);
            this.setState({loading: true, saveStatus: 1}, () =>{
                var token = localStorage.getItem(self.props.mySessKey);
                if(!token){
                    self.setState({ loading: false, saveStatus: 2 });
                }
                else if(saveList.length === 0){
                    self.setState({ loading: false, saveStatus: 0 });
                }
                else {
                    for(var i=0; i < saveList.length; i++){
                        var tmpUser = self.state.userList[saveList[i]];

                        var updateUrl = self.props.rootPath + ("_id" in tmpUser ?  "/api/auth/update" : "/api/auth/create");                        
                        var postData = ("_id" in tmpUser ? { aId: tmpUser._id, aUser:tmpUser } : { aUser:tmpUser });

                        // Remove Item from save list
                        delete updateDict[saveList[i]];

                        axios.post(updateUrl, postData, {headers:{'Content-Type': 'application/json', 'Authorization': token}})
                        .then(function(response) {
                            if(response.data.error){
                                console.log("[Error] Updating User: ", response.data.error);
                                alert(`Error updating user: ${response.data.error}`);
                            }
                        }).then(function(){
                            if(Object.keys(updateDict).length === 0){
                                self.setState({ saveStatus: 0 }, () =>{
                                    self.loadUserList();
                                });
                            }
                        }); 
                    }
                }
            });            
        }
        catch(ex){
            console.log("[Error] Saving User List: ",ex);
        }
    }

    deleteUser(deleteId, uName){
        var self = this;
        try {
            if(window.confirm(`Are you sure you want to delete '${uName}'?`)){
                this.setState({ loading: true }, ()=> {
                    var token = localStorage.getItem(self.props.mySessKey);
                    axios.get(self.props.rootPath + "/api/auth/delete/" + deleteId, {headers: {'Content-Type': 'application/json', 'Authorization': token}})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Deleting User (E1): ", response.data.error);
                        }
                        else {
                            self.loadDraftList();
                        }
                    }).catch(function (error) {
                        console.log("[Error] Deleting User (E2)",error);
                    })
                    .then(function(){
                        self.loadUserList();
                    });
                });  
            }
        }
        catch(ex){
            console.log("[Error] Deleting User", ex);
        }
    }

    createUser(){
        try {
            var tmpList = this.state.userList;
            tmpList.push({ userId: "", name:"", email:"", admin: false });
            this.setState({ userList: tmpList });
        }
        catch(ex){
            console.log("[Error] Creating User: ",ex);
        }
    }

    componentDidMount(){ this.getLoginInfo(); this.loadUserList(); }

    render(){  
        return(
            <div className="page-body admin users">
                {this.state.loading && <LoadSpinner colorClass={"c1"}/> }
                <Link className="back-btn" to="/admin">
                    <i className="fas fa-chevron-left" />
                    <span>Back To Admin Portal</span>
                </Link>
                <div className="login-info">
                    <span>Welcome, {this.state.loginName}</span>
                    <i className="far fa-times-circle" onClick={this.signOut}/>
                </div>
                <div className="title">
                    <img src={logo_c} alt="Gilmore Funeral Home Crest"/>
                    <h1>User Management Tool</h1>
                </div>

                <div className='user-section'>
                    <div className="save-status-text">
                        <i className={saveStatus[this.state.saveStatus].icon} />
                        <span>{saveStatus[this.state.saveStatus].title}</span>
                    </div>

                    <div className="title-btn-container">
                        <div className="btn-container">
                            <div className="link-btn" onClick={this.createUser}><i className="fas fa-user-plus" />Create User</div>
                        </div>
                    </div> 
                
                    <div className="user-list-container">
                        {this.state.userList.map((user, i) =>
                            <div className="user-form-container editor-form" key={i}>
                                <div className={"form-element sz-3" + (user.name.length < 1 ? " err" : "")}>
                                    <div className="element-title">Name</div>
                                    <input type="text" name="name" value={user.name} onChange={(el)=> this.onElementChange(el,i)}/>
                                </div>

                                <div className={"form-element sz-5" + (validateEmail(user.email) ? "" : " err")}>
                                    <div className="element-title">Email</div>
                                    <input type="text" name="email" value={user.email} onChange={(el)=> this.onElementChange(el,i)} disabled={this.state.localId === user._id}/>
                                </div>

                                {this.state.localId !== user._id &&
                                    <div className="form-element sz-1 noback">
                                        <div className="element-title">Admin</div>
                                                            
                                        <div className="toggle-btn-container">
                                            <div className={"toggle-btn" + (user.admin ? " active":"")} onClick={()=> this.toggleUserAdmin(i)}>
                                                <i className="fas fa-check" />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.localId !== user._id &&
                                    <div className="form-element sz-1 noback">
                                        <div className="element-title">Delete</div>
                                                            
                                        <div className="order-btn-container">
                                            <div className="order-btn" onClick={()=> this.deleteUser(user._id, user.name)}><i className="fas fa-times" /></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div> 
                </div>
            </div>
        );
    }
}
export default UserManagement;

/* Private Functions */
/* validate user */
function validateUser(user){
    try {
        var requiredFields = ["name"];
        // Validate name & userId
        for(var i=0; i < requiredFields.length; i++){
            if(!user[requiredFields[i]] || user[requiredFields[i]].length < 1){
                return false;
            }
        }

        // Validate Email
        if(!user.email || user.email.length < 2 || !validateEmail(user.email)){
            return false;
        }
    }
    catch(ex){
        console.log("[Error] Validating User: ", ex);
    }

    return true;
}

/* validate email address */
function validateEmail(email){
    try {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true;
        }
    }
    catch(ex){
        console.log("[Error] Validating Email Address: ", ex);
    }
    return false;
}