import React, { Component } from 'react';
import axios from 'axios';

import 'rodal/lib/rodal.css';
import Rodal from 'rodal';

import LoadSpinner from './loadSpinner';

import { rootPath, mySessKey } from '../utils';
const modalWidth = window.outerWidth ? (window.outerWidth >= 770 ? (window.outerWidth * .5) : (window.outerWidth * .9)) : 500, 
        modalHeight = (window.outerWidth && (window.outerWidth < 770) ? (window.outerHeight * .7) : 550);

class FamilyWordsModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name:"", image:"", messages:[], is_admin: false,
            newMessage:"", newAuthor:"", loading: false
        }

        this.onElementChange = this.onElementChange.bind(this);
        this.getServiceInfo = this.getServiceInfo.bind(this);
        this.addServiceMessage = this.addServiceMessage.bind(this);
        this.deleteServiceMessage = this.deleteServiceMessage.bind(this);
        this.checkUser = this.checkUser.bind(this);
    }
    
    onElementChange(event){
        var self = this;
        try {
            let name = event.target.name;

            self.setState({ [name]: event.target.value});
        }
        catch(ex){
            console.log("[ERROR] on element change: ",ex);
        }
    }

    getServiceInfo() {
        let self = this;
        try {
            if(this.props.selectedId) {
                this.setState({ loading: true, is_admin: this.checkUser() }, ()=>{
                axios.get(`${rootPath}/api/getService/${self.props.selectedId}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Service Info (E1): ", response.data.error);
                            self.setState({ loading: false });
                        }
                        else if(response.data.results){
                            self.setState({ 
                                loading: false, name: response.data.results.name,
                                image: response.data.results.image, 
                                messages: response.data.results?.messages ?? [],
                            });
                        }
                    }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] Getting Service Info: ${ex}`);
        }
    }

    addServiceMessage() {
        let self = this;
        try {
            if(this.props.selectedId && this.state.newMessage) {
                this.setState({ loading: true }, ()=>{
                    let postData = { id: self.props.selectedId,
                        message: self.state.newMessage, author:self.state.newAuthor 
                    };
                    axios.post(`${rootPath}/api/addServiceMessage`, postData, {'Content-Type': 'application/json'})
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(" [Error] Adding Service Message (E1): ", response.data.error);
                                self.setState({ loading: false });
                            }
                            else {
                                self.setState({ loading: false, newMessage:"", newAuthor: "" }, () => {
                                    self.getServiceInfo();
                                });
                            }
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] Adding Service Message: ${ex}`);
        }
    }

    deleteServiceMessage(timestamp) {
        let self = this;
        try {
            if(this.props.selectedId && timestamp) {
                this.setState({ loading: true }, ()=>{
                    let postData = { id: self.props.selectedId, timestamp: timestamp };
                    var sessionInfo = localStorage.getItem(mySessKey);
                    var getHeaders = { 'Content-Type': 'application/json', 'Authorization': sessionInfo };

                    axios.post(`${rootPath}/api/auth/deleteServiceMessage`, postData, { headers: getHeaders })
                        .then(function(response) {
                            if(response.data.error || !response.data.results){
                                console.log(" [Error] Deleting Service Message (E1): ", response.data.error);
                                self.setState({ loading: false });
                            }
                            else {
                                self.setState({ loading: false }, () => {
                                    self.getServiceInfo();
                                });
                            }
                        }); 
                });
            }
        }
        catch(ex){
            console.log(`[Error] Deleting Service Message: ${ex}`);
        }
    }

    checkUser(){
        try {
            let sessionInfo = localStorage.getItem(mySessKey);
            if(sessionInfo){
                var { isExpired } = parseToken(sessionInfo); 
                
                if(!isExpired) { return true; }
            }
        }
        catch(ex){
            console.log("Error Check Active User: ",ex);
        }

        return false;
    }

    componentDidMount(){ this.getServiceInfo(); }

    componentDidUpdate(prevProps){
        if(prevProps.selectedId !== this.props.selectedId && this.props.selectedId){
            this.getServiceInfo();
        }
    }

    render(){ 
        return( 
            <>
                <Rodal visible={this.props.show} onClose={()=> this.props.setShow(false)} animation={"zoom"} width={modalWidth} height={modalHeight}>
                    {this.state.loading && <LoadSpinner /> }
                    <div className='words-container'>
                        <h1 className='modal-title'>Words For The Family</h1>
                        <p>As they are going through a difficult time please feel free to leave some words to the family</p>
                        <div className='service-info-container'>
                            <div className='service-name-container'>
                                <div className='img-container'>
                                    {this.state.image?.length > 0 && <img src={this.state.image} alt={`${this.state.name} photo`} />}
                                </div>
                                <h2>{this.state?.name}</h2>
                            </div>
        
                            <div className='message-container'>
                                {this.state.messages.map((msg,i) =>
                                    <div className='msg-item' key={i}>
                                        <div className='item-message'>
                                            <div>{msg.message}</div>
                                            {this.state.is_admin && 
                                                <div className='remove-message' onClick={()=> this.deleteServiceMessage(msg.timestamp)}>
                                                    <i className="fas fa-times" />
                                                </div>
                                            }
                                        </div>
                                        <span>- {msg.author.length > 0 ? msg.author : "Anonymous"} -</span>
                                    </div>
                                )}
                                {this.state.messages.length === 0 && <div className='empty-messages'>Be The First To Leave A Message</div>}
                            </div>

                            <div className='new-message-container'>
                                <div className='message-form'>
                                    <textarea name="newMessage" value={this.state.newMessage} rows={1} onChange={this.onElementChange} placeholder='Please write a positive message for the family'/>
                                    <input name="newAuthor" value={this.state.newAuthor} onChange={this.onElementChange} placeholder='Share your name'/>
                                </div>
                                <div className='send-btn' onClick={this.addServiceMessage}>
                                    <i className="fas fa-paper-plane" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Rodal>
            </>
        )
    };

}

export default FamilyWordsModal;


function parseToken(token){
    var localUser = null, isExpired = true; 
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var jsStr = window.atob(base64);

        localUser = JSON.parse(jsStr);
        isExpired = (localUser && localUser.expDt < Date.now());
    }
    catch(ex){
        console.log("[Error] parsing token: ",ex);
    }

    return { localUser, isExpired }
}