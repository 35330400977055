import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { format, subDays} from 'date-fns';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import LoadSpinner from '../components/loadSpinner';
import FamilyWordsModal from '../components/familyWords';

import { rootPath } from '../utils';

class ServiceSchedule extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:false, search:"", start_dt: subDays(new Date(), 7),
            pageCount:1, currentPg:1, serviceList:[],
            showModal: false, selectedId: null
        }
        
        /* Functions */
        this.getServiceList = this.getServiceList.bind(this);
        this.parseDate = this.parseDate.bind(this);
        this.onElementChange = this.onElementChange.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.initialReactGA = this.initialReactGA.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);

        this.setModal = this.setModal.bind(this);
    }  

    getServiceList(search, page){
        var self = this;
        try {
            this.setState({ loading: true }, ()=>{
                let tmp_start_dt = format(self.state.start_dt,'MM-dd-yyyy');
                axios.get(`${rootPath}/api/getServices?search_query=${search}&size=${8}&page=${page}&start_dt=${tmp_start_dt}`, {'Content-Type': 'application/json'})
                    .then(function(response) {
                        if(response.data.error){
                            console.log(" [Error] Getting Service List(1): ", response.data.error);
                            self.setState({loading:false});
                        }
                        else if(response.data.results.list && response.data.results.list.length >= 0){
                            self.setState({loading:false, serviceList: response.data.results.list, pageCount: response.data.results.pageCount });
                        }
                    }); 
            });
        }
        catch(ex){
            console.log(" [Error] Getting Service List: ",ex);
        }
    }

    parseDate(stdate, type){
        var ret = null;
        var Month = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];

        try {
            if(!stdate) { return null; }
            var date = new Date(stdate);
            switch(type){
                case "time":
                    ret = ((date.getHours() > 12) ? (date.getHours() - 12) : date.getHours()) +":"
                    + ((date.getMinutes() < 10) ? "0"+ date.getMinutes() : date.getMinutes())
                    + ((date.getHours() >= 12) ? " PM" : " AM");
                    break;
                case "date":
                    ret = Month[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
                    break;
                default:
                    ret = null;
                    break;
            }
        }
        catch(ex){
            console.log("Error parsing date: ", ex);
        }
        return ret;
    }

    onElementChange(event){
        var self = this;
        try {
            var name = event.target.name;

            if(name in this.state) {
                self.setState({ [name]: event.target.value, currentPg:1 }, () =>{
                    self.getServiceList(self.state.search, self.state.currentPg);
                });
            }
        }
        catch(ex){
            console.log("[ERROR] on element change: ",ex);
        }
    }

    pageChange(type){
        var self = this;
        try {
            if(type === "prev"){
                if(this.state.currentPg > 1){
                    this.setState({ currentPg: this.state.currentPg - 1}, () =>{
                        self.getServiceList(self.state.search, self.state.currentPg);
                    })
                }
            }
            else {
                if(this.state.currentPg < this.state.pageCount){
                    this.setState({ currentPg: this.state.currentPg + 1}, () =>{
                        self.getServiceList(self.state.search, self.state.currentPg);
                    });
                }
            }
        }
        catch(ex){
            console.log("[ERROR] page change: ",ex);
        }
    }

    setModal(status){
        this.setState({ showModal: status, selectedId: (status ? this.state.selectedId : null) });
    }

    initialReactGA(){ ReactGA.initialize('UA-216091573-1'); ReactGA.pageview('/serviceschedule'); }

    handleDateRangeChange(e){
        let self = this;
        try {
            var date = e._d;
            this.setState({ "start_dt": date }, ()=> {
                self.getServiceList(self.state.search, 1); 
            });
        }
        catch(ex){
            console.log(" [Error] Handling Date Range Change: ",ex);
        }
    }

    componentDidMount(){ 
        window.scrollTo(0, 0);
        document.title = "Service Schedule - Roy L. Gilmore Funeral Home, Inc.";
        this.initialReactGA(); this.getServiceList("", 1);
    }

    render(){ 
        return(
            <div className="page-body services">
                {this.state.loading && <LoadSpinner /> }
                <section className="sub-header">
                    <div className="header-container">
                        <div className="sub-content sub-title">
                            <h1>Service<br />Schedule</h1>
                        </div>
                        <div className="sub-content sub-text">
                            <p>A tradition passed on from generation to generation, the Gilmore family has dedicated their lives to serving families in their time of need. Our Service Schedule tool can be used to help you find more information about your loved ones service. </p>
                        </div>
                    </div>
                </section>
                <section className="service-list">
                    <div className="list-header">
                        <h1>Planned Services</h1>
                        <div className='search-field-container'>
                            <div className="search-container date">
                                <div className="search-field">
                                    <i className="fas fa-calendar-alt" />
                                    <Datetime value={this.state.start_dt} onChange={this.handleDateRangeChange} displayTimeZone={null} timeFormat={false} renderInput={(props) => {
                                        return <input {...props} value={(this.state.start_dt) ? props.value : ''} />
                                    }}/>
                                </div>
                            </div>
                            <div className="search-container">
                                <div className="search-field">
                                    <i className="fas fa-search" />
                                    <input type="text" name="search" placeholder="Search Name" onChange={(e) => this.onElementChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='table-conatiner'>
                        <table className="service-table hover-row">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Viewing Date & Time</th>
                                    <th>Service Date & Time</th>
                                    <th>Service Location</th>
                                    <th>Words For The Family</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.serviceList.map((service, i) => (
                                    <tr key={i}>
                                        <td>
                                            <div className='list-img-container'>
                                                {service?.image ? <img src={service.image} alt={`${service.name} Image`} /> :
                                                    <div className='empty-img' />
                                                }
                                            </div>
                                        </td>
                                        <td>{service.name}</td>
                                        <td>{this.parseDate(service.viewing,"date")} {this.parseDate(service.viewing,"time")}</td>
                                        {!service?.date ?
                                            <td><span className="special-note">Service TBD</span></td> :
                                            <td>{this.parseDate(service.date,"date")} {this.parseDate(service.date,"time")}</td> 
                                        }
                                        <td className='location'>
                                            <span>{service.location}</span>
                                            <span className='address'>{service.address}</span>
                                        </td>
                                        <td>
                                            <div className="view-link" onClick={()=> this.setState({ showModal: true, selectedId: service._id }) }>
                                                <span>View Messages</span>
                                                <i className="fas fa-book-reader" />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {this.state.serviceList.length === 0 &&
                                    <tr className="empty-table"><td colSpan={6}>No Services Found At This Time</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {this.state.serviceList.length > 0 &&
                        <div className="table-control">
                            <div className="scroll-ctrl">
                                <div className={"ctrl-item left" + (this.state.currentPg === 1 ? " disabled":"")} onClick={() => this.pageChange("prev")}><i className="fas fa-chevron-left"/></div>
                                <div className={"ctrl-item right" + (this.state.currentPg === this.state.pageCount ? " disabled":"")} onClick={() => this.pageChange("next")}><i className="fas fa-chevron-right"/></div>
                            </div>

                            <div className="page-info">
                                <div className="pg-info">Page {this.state.currentPg} of {this.state.pageCount}</div>                            
                            </div>
                        </div>
                    }
                </section>

                <FamilyWordsModal show={this.state.showModal} setShow={this.setModal} selectedId={this.state.selectedId} />
            </div>
        );
    }
}
export default ServiceSchedule;